import { Plan } from './components/Plan';
import withTableController from '../../../../HOC/tableController/withTableControllerForPlanning';

import { getDefaultData } from './utilities/defaultData';

const defaultPlan = getDefaultData();

const Plan12 = withTableController(({ planing, handlePlaning }) => (
  <Plan
    planing={planing.length ? planing : defaultPlan}
    handlePlaning={handlePlaning}
  />
));

export default Plan12;
