import React from 'react';
import { Navigate } from 'react-router-dom';
import { Flex } from 'antd';

import { Login } from './components/Login';

import auth from '../../services/auth.services';

import { homeSectionStruct } from './struct';

import './styles.css';

const Homepage = () => {
  if (auth.retriveToken() && auth.retriveToken() !== 'undefined')
    return <Navigate to="/home" />;
  else {
    return (
      <>
        <div className="container">
          <div className="screen">
            <Flex {...homeSectionStruct}>
              <Login />
              <div className="screen__background">
                <div className="screen__background__shape screen__background__shape0"></div>
                <div className="screen__background__shape screen__background__shape1"></div>
                <div className="screen__background__shape screen__background__shape2"></div>
                <div className="screen__background__shape screen__background__shape3"></div>
                <div className="screen__background__shape screen__background__shape4"></div>

                <div className="screen__background__shape screen__background__shapeExtra"></div>
              </div>

              <div className="screen__overlay"></div>
            </Flex>
          </div>
        </div>
      </>
    );
  }
};

export default Homepage;
