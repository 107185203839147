import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import { date, vrFlex, hrFlex } from './struct';
// import { Timer } from '../Timer';

const { Text } = Typography;

const dateFormat = 'YYYY';

export const CustomDatePicker = ({ handleSelectedYear, selectedYear }) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { year: yearsDes } = staticData;

  const [currentYearDes, setCurrentYearDes] = useState(null);
  const {
    planning_start_date: planningStartDate,
    planning_end_date: planningEndDate
  } = currentYearDes || {};
  const handleCurrentYearDes = (des) => setCurrentYearDes(des);

  const [years, setYears] = useState([]);
  const handleYears = (years) => setYears(years);

  const [minYear, setMinYear] = useState('');
  const handleMinYear = (year) => setMinYear(year);

  const [maxYear, setMaxYear] = useState('');
  const handleMaxYear = (year) => setMaxYear(year);

  useEffect(() => {
    if (yearsDes) {
      const _years = yearsDes ? yearsDes.map((yearDes) => yearDes.year) : [];
      const _minYear = Math.min(..._years);
      const _maxYear = Math.max(..._years);

      handleYears(_years);
      handleMinYear(String(_minYear));
      handleMaxYear(String(_maxYear));
    }
  }, [yearsDes]);

  useEffect(() => {
    if (yearsDes) {
      const selectedYearDes = yearsDes.filter(
        (yearDes) => yearDes.year === selectedYear
      );
      handleCurrentYearDes(selectedYearDes.length ? selectedYearDes[0] : null);
    }
  }, [selectedYear]);

  return years.length && minYear && maxYear ? (
    <Flex {...vrFlex}>
      <DatePicker
        {...date}
        defaultValue={dayjs(`${selectedYear}`, 'YYYY')}
        onChange={handleSelectedYear}
        minDate={dayjs(minYear, dateFormat)}
        maxDate={dayjs(maxYear, dateFormat)}
      />
      {currentYearDes ? (
        <>
          <Flex {...hrFlex}>
            <Text>
              Planning Start Date:{' '}
              {moment(planningStartDate).format('MMMM - DD, YYYY')}
            </Text>{' '}
            ~{' '}
            <Text>
              Planning End Date:{' '}
              {moment(planningEndDate).format('MMMM - DD, YYYY')}
            </Text>
          </Flex>
          {/* <Timer planningEndDate={planningEndDate} /> */}
        </>
      ) : (
        <></>
      )}
    </Flex>
  ) : (
    <></>
  );
};
