export const table = {
    wrapper: {
        style: {
            borderRadius: "8px",
        },
    },


    headline: {
        level: 5
    },
    
    img:{
        style:{
            justifyContent:'center',
            alignItem:'center',
            marginTop:'20px'
        }
    }

};
