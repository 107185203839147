import { useSelector } from 'react-redux';
import { Select, Input } from 'antd';

const getOptions = (list) => {
  return list.map((item) => ({
    value: item.value,
    label: <div>{item.label}</div>
  }));
};

export const TableCol = (handleSave) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { supervision_course: supervisionCourse } = staticData;

  return [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Course',
      render: (value, row) => {
        const _courseType =
          supervisionCourse && supervisionCourse.length
            ? supervisionCourse.filter((item) => item.value === Number(value))
            : [];
        const _course = _courseType.length ? _courseType[0] : {};

        const { value: courseValue } = _course;

        return supervisionCourse && supervisionCourse.length ? (
          <Select
            style={{ width: '250px' }}
            options={getOptions(supervisionCourse)}
            defaultValue={courseValue}
            placeholder="Select a Course"
            onChange={(e) => {
              handleSave({
                ...row,
                name: e
              });
            }}
          />
        ) : (
          <></>
        );
      }
    },
    {
      key: 'no_student',
      dataIndex: 'no_student',
      title: 'No. of students',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) => handleSave({ ...row, no_student: e.target.value })}
        />
      )
    }
  ];
};
