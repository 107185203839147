import apis from "../services/Apis";
import { SecureGet } from "../services/axiosCall";

import { responseStatus } from "../const/reponseStatus";

export const getStatic = () => (dispatch) => {
  dispatch({
    type: "STATIC",
    loading: true,
    data: [],
    error: true,
    errorMsg: "",
  });

  SecureGet({ url: apis.STATIC })
    .then((response) => {
      if (responseStatus[response.status] === "SUCCESS") {
        dispatch({
          type: "STATIC",
          loading: false,
          data: response.data,
          error: true,
          errorMsg: "",
        });
      } else {
        dispatch({
          type: "STATIC",
          loading: false,
          data: [],
          error: true,
          errorMsg: "Something went wrong",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "STATIC",
        loading: false,
        data: [],
        error: true,
        errorMsg: "Server Error",
      });
    });
};
