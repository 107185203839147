import { combineReducers } from "redux";

import authAction from "./loggedinuser";
import apaAction from "./apa";
import menuAction from "./menu";
import staticAction from "./static";

export default combineReducers({
  user: authAction,
  apa: apaAction,
  menu: menuAction,
  static: staticAction,
});
