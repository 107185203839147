import { getDefaultData } from './defaultData';

const defaultData = getDefaultData();

export const convertToTableData = (data) => {
  data.forEach((plan) => {
    const semesterIndex = `semester${plan.semester}`;

    defaultData[0][semesterIndex] = plan.course_module_design;
    defaultData[1][semesterIndex] = plan.taking_per_audit;
    defaultData[2][semesterIndex] = plan.course_file_completion;
    defaultData[3][semesterIndex] = plan.new_course_teaching;
  });

  return defaultData;
};

export const convertToRequestData = (data) => {
  const output = [];

  // Iterate through semesters
  for (let semester = 1; semester <= 3; semester++) {
    const semesterData = {
      semester: semester
    };

    // Iterate through input items
    for (const item of data) {
      const key = item.apa.id;
      semesterData[key] = item[`semester${semester}`];
    }

    output.push(semesterData);
  }

  return output;
};
