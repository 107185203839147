import apis from "../services/Apis";
import { SecureGet } from "../services/axiosCall";

import { responseStatus } from "../const/reponseStatus";

export const getApa = () => (dispatch) => {
  dispatch({
    type: "APA",
    loading: true,
    data: [],
    error: true,
    errorMsg: "",
  });

  SecureGet({ url: apis.FORM_LIST })
    .then((response) => {
      if (responseStatus[response.status] === "SUCCESS") {
        dispatch({
          type: "APA",
          loading: false,
          data: response.data.results,
          error: true,
          errorMsg: "",
        });
      } else {
        dispatch({
          type: "APA",
          loading: false,
          data: [],
          error: true,
          errorMsg: "Something went wrong",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "APA",
        loading: false,
        data: [],
        error: true,
        errorMsg: "Server Error",
      });
    });
};
