export const staticStruct = {
    wrapper: {
      vertical: true,
    //   gap: "middle",
      style: {
        background: "rgb(245, 245, 245)",
        // borderRadius: "8px",
      },
    },
    table: {
      bordered: true,
      pagination: false,
      scroll: {
        x: 500,
      },
    },

  };
  