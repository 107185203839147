import apis from "./Apis";
import { Get, Post } from "./axiosCall";



class AuthService {
  constructor() {
    this.token = null;
  }

  storeToken = (t) => {
    localStorage.setItem("Token", t);
  };

  storeUserInfo = (t) => {
    localStorage.setItem("UserInfo", t);
  };

  retriveToken = () => {
      return localStorage.getItem("Token");
  };

  retriveUserInfo = () => {
    return localStorage.getItem("UserInfo");
  };

  deleteUserInfo = () => {
    localStorage.removeItem("UserInfo");
  };

  deleteToken = () => {
    localStorage.removeItem("Token");
  };

  LoginAuth = ({ email, password }) => {
    return Post({
      url: apis.LOGIN,
      data: {
        email,
        password,
      },
    });
  };

  FetchAuth = (t) => {
    return Get({
      url: apis.GETDETAILSUSER,
      headers: {
        Authorization: `${t}`,
      },
    });
  };

  RefreshToken = () => {
    const userInfoString = this.retriveUserInfo()
    const userInfo = JSON.parse(userInfoString);

    const { refresh } = userInfo || {}

    return Post({
      url: apis.REFRESH_TOKEN,
      data: {
        refresh
      }
    })
  }
}

const auth = new AuthService();
export default auth;
