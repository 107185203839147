import React from 'react';

import { Modal } from 'antd';
import * as AntdIcons from '@ant-design/icons';

export const confirmations = {
  modal: Modal.confirm,
  forRouteChange: function (action) {
    this.modal({
      title:
        "You haven't save your form. If you change the route , all unsaved data will lost. Do you want change the route?",
      icon: <AntdIcons.ExclamationCircleFilled />,
      content: '',
      onOk() {
        action();
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  }
};
