import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Popconfirm, Flex, Button } from 'antd';

import { TableCol } from './getTableCol';

import { planStruct } from './struct';
import { getRandomId } from '../../../../../../utilities/randomId';
import { getAsNumber } from '../../../../../../utilities/numberConvertion';

export const Plan = ({ planing, handlePlaning }) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const {
    research_grant_type: researchGrantType,
    research_academic_grant_status: researchGrantStatus
  } = staticData;

  const handleAdd = () => {
    const newPlaning = {
      key: getRandomId(),
      application_type: researchGrantType[0].value,
      detail: '',
      is_lead_applicant: false,
      status: researchGrantStatus?.[0]?.value
    };
    handlePlaning([...planing, newPlaning]);
  };

  const handleSave = (row) => {
    const currentOutcome = [...planing];
    const index = currentOutcome.findIndex((item) => row.key === item.key);
    const item = currentOutcome[index];

    row.application_type = getAsNumber(row.application_type);

    currentOutcome.splice(index, 1, {
      ...item,
      ...row
    });
    handlePlaning(currentOutcome);
  };

  const handleDelete = (key) => {
    const newData = planing.filter((item) => item.key !== key);
    handlePlaning(newData);
  };

  const columns = [
    ...TableCol(handleSave),
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record.key)}
        >
          <a>Delete</a>
        </Popconfirm>
      )
    }
  ];

  return (
    <>
      <Table {...planStruct.table} columns={columns} dataSource={planing} />
      <br />
      <Flex {...planStruct.actionSection}>
        <Button onClick={handleAdd}>Add New Row</Button>{' '}
      </Flex>
    </>
  );
};
