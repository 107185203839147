export const formRules = [
  {
    id: "1.1.A",
    rules: [
      `APA Points will be calculated against Annual Average of Course Evaluation Score including all courses conducted (develop, deliver and evaluate) in the APA Cycle.
      Following the scheme below:
      <br/>
      3.00-3.49=120, 3.5-3.99=140, 4.00-4.49=160, 4.50-4.89=180, 4.90-5.00=200 APA Points Max.`
    ],
  },
  {
    id: "1.1.B",
    rules: [''],
  },
  {
    id: "1.2",
    rules: [`APA Points will be calculated against successful completion of following activities. Maximum APA point is 100.`],
  },
  {
    id: "1.3",
    rules: [`5 APA Points/ Advising Session rostered the department/school. Per sessions minimum 2 hours, max 100 APA Points`],
  },
  {
    id: "2.1",
    rules: [`APA points will be calculated against published research outcomes in the APA Cycle. Points vary as per the category and venue of publishing as specified in first right column. Max APA point is 200.`],
  },
  {
    id: "2.2",
    rules: [
      `<b>Grant Application:</b> 20 APA points for the lead applicant of an International grant proposal submission and 10 APA points for each of the team members.
      10 APA points for the lead applicant of a National/IUB grant proposal submission and 5 APA points for each of the team members.<br/><br/>`,
      `<b>Grant Success:</b> IUB Grant (20/10 APA points), National Grant (20/10 APA points) International (50/20 APA points) [Lead Applicant/ Member]. 
      Max. APA points is 100.`
    ]
  },
  {
    id: "2.3",
    rules: [
      `Research projects commissioned by any industry actor or stake holders. 
      Total 20 APA points for each Completed Project and additional 2 APA points for per IUB RA Involved in the project.
      Total APA points will be shared among the Faculty Member/s involved. Max. APA point is 50.`
    ],
  },
  {
    id: "2.4",
    rules: [`Presenting/attending in research and training events will carry 30 APA points max.`],
  },
  {
    id: "2.5",
    rules: [`Presenting/attending in research and training events will carry 30 APA points max.`],
  },
  {
    id: "3.1",
    rules: [`Max. 200 APA points are allocated for performing following academic roles and services:`],
  },
  {
    id: "3.2",
    rules: [`Max. 200 APA points are allocated for performing following co- and extracurricular roles and services:`],
  },
];
