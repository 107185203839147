import { Input } from 'antd';

import { getRandomId } from '../../../../../../utilities/randomId';

export const TableCol = (handleSave) => {
  return [
    {
      key: getRandomId(),
      dataIndex: 'apa',
      title: 'APA Area',
      render: (value) => <>{value.area}</>
    },
    {
      key: getRandomId(),
      dataIndex: 'apa',
      title: 'APA Scoring Method',
      render: (value) => <>{value.scoringMethod}</>
    },
    {
      key: 'semester1',
      dataIndex: 'semester1',
      title: 'Spring',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) => handleSave({ ...row, semester1: e.target.value })}
        />
      )
    },
    {
      key: 'semester2',
      dataIndex: 'semester2',
      title: 'Summer',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) => handleSave({ ...row, semester2: e.target.value })}
        />
      )
    },
    {
      key: 'semester3',
      dataIndex: 'semester3',
      title: 'Autumn',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) => handleSave({ ...row, semester3: e.target.value })}
        />
      )
    }
  ];
};
