//current apa
const initialState = {
    selectedApas: [],
    currentApaId: null,
    currentApaStatus: null,
    currentMenuId: null,
    currentMenuType: null,
    nextMenu: null,
    prevMenu: null,
}

const menuAction = (state = initialState, action) => {
    switch (action.type) {
        case "SELECT_MENU": {
            return {
                ...state,
                selectedApas: action.data.selectedApas,
                currentMenuId: action.data.menuId,
                currentMenuType: action.data.menuType,
                nextMenu: action.data.nextMenu,
                prevMenu: action.data.prevMenu,
                currentApaId:action.data.apaId,
                currentApaStatus:action.data.apaStatus,
            };
        }

        default:
            return state;
    }
};

export default menuAction;