import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Button, message, Card, Skeleton, Typography } from 'antd';

import { Semester } from './components/Semester';
import { CustomDatePicker } from '../../../../components/CustomDatePicker';

import apis from '../../../../services/Apis';

import { table } from './struct';
import { getRandomId } from '../../../../utilities/randomId';
import usePlanning from '../../../../hooks/usePlanning';

const { Title } = Typography;

const Plan11 = ({ currentApas, tableNo, title }) => {
  const apa = currentApas.find((item) => item.form_no === tableNo) || {};
  const [messageApi, contextHolder] = message.useMessage();

  const user = useSelector((state) => state.user);
  const { userDetails } = user;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [selectedYear, setSelectedYear] = useState(Number(currentYear));
  const handleSelectedYear = (_, yearString) =>
    setSelectedYear(Number(yearString));

  const {
    planing,
    updated,
    fetchLoading,
    updateLoading,
    updatePlaning,
    handlePlaning
  } = usePlanning({
    url: apis.PLANING,
    formId: apa.id,
    userDetails: userDetails.user_id,
    selectedYear: selectedYear,
    messageApi: messageApi
  });

  const removeSemester = (semester) =>
    handlePlaning(planing.filter((item) => item.semester !== semester));

  const addSemester = () =>
    handlePlaning([
      ...planing,
      {
        semester: '',
        tempSemester: getRandomId()
      }
    ]);

  //Only temp semester name will be update
  const setSemesterName = (semesterName, id) => {
    const index = planing.findIndex((item) => item.semester === semesterName);
    if (index < 0) {
      handlePlaning(
        planing.map((item) =>
          item.tempSemester === id
            ? {
                ...item,
                tempSemester: null,
                semester: semesterName,
                courses: []
              }
            : { ...item }
        )
      );
    } else {
      messageApi.error('Can not create duplicate semester');
    }
  };

  const updateSemester = (semesterName, courses) => {
    handlePlaning(
      planing.map((item) =>
        item.semester === semesterName
          ? {
              ...item,
              courses
            }
          : { ...item }
      )
    );
  };

  return (
    <Card {...table.wrapper}>
      {contextHolder}
      <Title level={5}>{title ? title : ''}</Title>
      <br />
      <Flex {...table.header}>
        <CustomDatePicker
          selectedYear={selectedYear}
          handleSelectedYear={handleSelectedYear}
        />
      </Flex>
      <br />
      {planing && !fetchLoading ? (
        planing.map((semester) => (
          <>
            <Semester
              key={semester.semester}
              semesterDetail={semester}
              removeSemester={removeSemester}
              setSemesterName={setSemesterName}
              updateSemester={updateSemester}
            />
            <br />
          </>
        ))
      ) : (
        <Skeleton loading={fetchLoading} active />
      )}

      <Flex {...table.header}>
        <Button {...table.addSemesterButton} onClick={() => addSemester()}>
          Add New Semester
        </Button>
        {updated ? (
          <Button
            {...table.saveButton}
            loading={updateLoading}
            onClick={() => updatePlaning()}
          >
            Save
          </Button>
        ) : (
          <></>
        )}
      </Flex>
    </Card>
  );
};

export default Plan11;
