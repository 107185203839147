import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Card, Flex, Button, message, Skeleton } from 'antd';

import { CustomDatePicker } from '../../components/CustomDatePicker';

import { RemainingTimeContext } from '../../context/TimerContext';
import usePlanning from '../../hooks/usePlanning';
import apis from '../../services/Apis';

import { table } from './struct';

const withTableController = (Table) => {
  // eslint-disable-next-line react/display-name
  return ({ currentApas, tableNo, dataConverter, hasReset }) => {
    const apa = currentApas.find((item) => item.form_no === tableNo) || {};
    const [messageApi, contextHolder] = message.useMessage();

    const { timer } = useContext(RemainingTimeContext);

    const user = useSelector((state) => state.user);
    const { userDetails } = user;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [selectedYear, setSelectedYear] = useState(Number(currentYear));
    const handleSelectedYear = (_, yearString) =>
      setSelectedYear(Number(yearString));

    const { toTableData, toRequestData } = dataConverter || {};

    const {
      planing,
      updated,
      fetchLoading,
      updateLoading,
      updatePlaning,
      handlePlaning
    } = usePlanning({
      url: apis.PLANING,
      formId: apa.id,
      userDetails: userDetails.user_id,
      selectedYear: selectedYear,
      messageApi: messageApi,
      convertToTableData: toTableData,
      convertToRequestData: toRequestData
    });

    return (
      <Card {...table.wrapper}>
        {contextHolder}

        <Flex {...table.header}>
          <CustomDatePicker
            selectedYear={selectedYear}
            handleSelectedYear={handleSelectedYear}
          />
        </Flex>
        {planing && !fetchLoading ? (
          <Table planing={planing} handlePlaning={handlePlaning} />
        ) : (
          <Skeleton loading={fetchLoading} active />
        )}

        <br />
        <Flex {...table.header}>
          <div></div>
          {timer ? (
            <Flex {...table.actionsSection}>
              {updated ? (
                <Button
                  {...table.saveButton}
                  loading={updateLoading}
                  onClick={() => updatePlaning()}
                >
                  Save
                </Button>
              ) : (
                <></>
              )}
              {hasReset && (
                <Button
                  {...table.resetButton}
                  loading={updateLoading}
                  onClick={() => handlePlaning([])}
                >
                  Reset
                </Button>
              )}
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
      </Card>
    );
  };
};

export default withTableController;
