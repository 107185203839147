import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Select, Typography, Input, Checkbox } from 'antd';

const { Text } = Typography;

export const TableCol = (handleSave) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { academic_responsibilities_roles: academicResponsibilitiesRoles } =
    staticData;

  const oncePerCycle = academicResponsibilitiesRoles.filter(
    (item) => item.once_per_cycle
  );

  return [
    {
      key: 'role',
      dataIndex: 'role',
      title: 'Role',
      render: (value, row) => {
        const _roles = academicResponsibilitiesRoles.filter(
          (item) => item.id === value
        );
        const _role = _roles[0];

        return (
          <Select
            style={{ width: '420px', minHeight: '64px' }}
            options={[
              ...academicResponsibilitiesRoles.map((role) => ({
                value: role.id,
                label: role ? (
                  <Flex vertical>
                    <Text>{role.role}</Text>
                    {role.rule ? (
                      <Text type="secondary">({role.rule})</Text>
                    ) : (
                      ''
                    )}
                  </Flex>
                ) : (
                  <></>
                )
              }))
            ]}
            defaultValue={_role.id}
            placeholder="Select a Event"
            onChange={(e) => {
              const _oncePerCycle = oncePerCycle.filter(
                (item) => item.id === e
              );
              const isOncePerCycle = _oncePerCycle && _oncePerCycle.length;

              const updatedRow = isOncePerCycle
                ? {
                    ...row,
                    semester_1: '',
                    semester_2: '',
                    semester_3: '',
                    apa_cycle: false,
                    role: e
                  }
                : { ...row, role: e };
              handleSave(updatedRow);
            }}
          />
        );
      }
    },
    {
      key: 'semester_1',
      dataIndex: 'semester_1',
      title: 'Spring',
      render: (value, row) => {
        const _oncePerCycle = oncePerCycle.filter(
          (item) => item.id === row.role
        );
        return _oncePerCycle && _oncePerCycle.length ? (
          ''
        ) : (
          <Input
            placeholder="Enter"
            value={value}
            onChange={(e) => handleSave({ ...row, semester_1: e.target.value })}
          />
        );
      }
    },
    {
      key: 'semester_2',
      dataIndex: 'semester_2',
      title: 'Summer',
      render: (value, row) => {
        const _oncePerCycle = oncePerCycle.filter(
          (item) => item.id === row.role
        );
        return _oncePerCycle && _oncePerCycle.length ? (
          ''
        ) : (
          <Input
            placeholder="Enter"
            value={value}
            onChange={(e) => handleSave({ ...row, semester_2: e.target.value })}
          />
        );
      }
    },
    {
      key: 'semester_3',
      dataIndex: 'semester_3',
      title: 'Autumn',
      render: (value, row) => {
        const _oncePerCycle = oncePerCycle.filter(
          (item) => item.id === row.role
        );
        return _oncePerCycle && _oncePerCycle.length ? (
          ''
        ) : (
          <Input
            placeholder="Enter"
            value={value}
            onChange={(e) => handleSave({ ...row, semester_3: e.target.value })}
          />
        );
      }
    },
    {
      key: 'apa_cycle',
      dataIndex: 'apa_cycle',
      title: 'APA Cycle',
      render: (value, row) => {
        const _oncePerCycle = oncePerCycle.filter(
          (item) => item.id === row.role
        );
        return !(_oncePerCycle && _oncePerCycle.length) ? (
          ''
        ) : (
          <Checkbox
            placeholder="Enter"
            defaultChecked={value}
            onChange={(e) =>
              handleSave({ ...row, apa_cycle: e.target.checked })
            }
          />
        );
      }
    }
  ];
};
