import { useState, React } from 'react';

import Plan11 from './1.1';
import Plan11B from './1.1.B';
import Plan12 from './1.2';
import Plan13 from './1.3';
import Plan21 from './2.1';
import Plan22 from './2.2';
import Plan23 from './2.3';
import Plan24 from './2.4';
import Plan25 from './2.5';
import Plan31 from './3.1';
import Plan32 from './3.2';

import { RemainingTimeContext } from './../../../context/TimerContext';

import {
  convertToRequestData,
  convertToTableData
} from './1.2/utilities/convertData';

const Plan = ({ menu }) => {
  const { selectedApas, currentMenuId } = menu;

  if (currentMenuId === '1.1')
    return (
      <>
        <Plan11
          currentApas={selectedApas}
          tableNo={'1.1.A'}
          title="A~Teaching and Evaluation"
        />
        <br />
        <Plan11B
          currentApas={selectedApas}
          tableNo={'1.1.B'}
          title="B~Supervision"
        />
      </>
    );
  else if (currentMenuId === '1.2')
    return (
      <Plan12
        currentApas={selectedApas}
        tableNo={'1.2'}
        dataConverter={{
          toTableData: convertToTableData,
          toRequestData: convertToRequestData
        }}
        hasReset={true}
      />
    );
  else if (currentMenuId === '1.3')
    return (
      <Plan13 currentApas={selectedApas} tableNo={'1.3'} hasReset={true} />
    );
  else if (currentMenuId === '2.1')
    return <Plan21 currentApas={selectedApas} tableNo={'2.1'} />;
  else if (currentMenuId === '2.2')
    return <Plan22 currentApas={selectedApas} tableNo={'2.2'} />;
  else if (currentMenuId === '2.3')
    return <Plan23 currentApas={selectedApas} tableNo={'2.3'} />;
  else if (currentMenuId === '2.4')
    return <Plan24 currentApas={selectedApas} tableNo={'2.4'} />;
  else if (currentMenuId === '2.5')
    return <Plan25 currentApas={selectedApas} tableNo={'2.5'} />;
  else if (currentMenuId === '3.1')
    return <Plan31 currentApas={selectedApas} tableNo={'3.1'} />;
  else if (currentMenuId === '3.2')
    return <Plan32 currentApas={selectedApas} tableNo={'3.2'} />;
  else return <div>Table Not Found</div>;
};

export const Plans = ({ menu }) => {
  const [timer, setTimer] = useState(1000);

  return (
    <RemainingTimeContext.Provider value={{ timer, setTimer }}>
      <Plan menu={menu} />
    </RemainingTimeContext.Provider>
  );
};
