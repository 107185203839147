import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Popconfirm, Flex, Button } from 'antd';

import { TableCol } from './getTableCol';

import { planStruct } from './struct';
import { getRandomId } from '../../../../../../utilities/randomId';

export const Plan = ({ planing, handlePlaning }) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { events } = staticData;

  const handleAdd = () => {
    const newPlaning = {
      key: getRandomId(),
      event: events[0].id,
      detail: ''
    };
    handlePlaning([...planing, newPlaning]);
  };

  const handleSave = (row) => {
    const currentCourses = [...planing];
    const index = currentCourses.findIndex((item) => row.key === item.key);
    const item = currentCourses[index];

    currentCourses.splice(index, 1, {
      ...item,
      ...row
    });
    handlePlaning(currentCourses);
  };

  const handleDelete = (key) => {
    const newData = planing.filter((item) => item.key !== key);
    handlePlaning(newData);
  };

  const columns = [
    ...TableCol(handleSave),
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record.key)}
        >
          <a>Delete</a>
        </Popconfirm>
      )
    }
  ];

  return (
    <>
      <Table {...planStruct.table} columns={columns} dataSource={planing} />
      <br />
      <Flex {...planStruct.actionSection}>
        <Button onClick={handleAdd}>Add New Row</Button>{' '}
      </Flex>
    </>
  );
};
