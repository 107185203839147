export const header = {
    section: {
      wrap: "wrap",
      justify: "space-between",
      gap: "middle",
    },
  
    headline: {
      level: 5,    
    },
  
    tagSection: {
      align: "center",
      gap: "middle",
    },
  
    tag: {
      color: "success",
    },
  };
  