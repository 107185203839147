import { useSelector } from 'react-redux';
import { Input } from 'antd';

export const TableCol = (handleSave) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { semester: semesters } = staticData;

  return [
    {
      key: 'semester',
      dataIndex: 'semester',
      title: 'Semester',
      render: (value) => {
        const _semesters = semesters.filter((item) => item.value === value);
        const _semester = _semesters[0];
        return <>{_semester.label}</>;
      }
    },
    {
      key: 'number_of_students',
      dataIndex: 'number_of_students',
      title: 'Number of sessions',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) =>
            handleSave({ ...row, number_of_students: e.target.value })
          }
        />
      )
    }
  ];
};
