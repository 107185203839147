import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';

import CustomLayout from './pages/Layout';
import Homepage from './pages/Homepage';

import store from './store';
import { IsFormSaved } from './context/FromStatusContext';

import './global.css';
import './App.css';

function App() {
  const [isFormSaved, setIsFormSaved] = useState(true);

  const [isDarkMode, setIsDarkMode] = useState(false);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const handleClick = () => setIsDarkMode((previousValue) => !previousValue);

  return (
    <IsFormSaved.Provider value={{ isFormSaved, setIsFormSaved }}>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
            token: {
              colorPrimary: '#5a5ab5',
              colorTextSecondary: '#812990',
              colorError: '#ff8399',
              colorWarning: '#f6941c',
              fontFamily: '"Roboto Slab", serif'
            }
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route
                exact
                path="/home"
                element={
                  <CustomLayout
                    isDarkMode={isDarkMode}
                    handleTheme={handleClick}
                  />
                }
              />
              <Route
                path="/:status/:formNo"
                element={
                  <CustomLayout
                    isDarkMode={isDarkMode}
                    handleTheme={handleClick}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </Provider>
    </IsFormSaved.Provider>
  );
}

export default App;
