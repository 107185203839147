import React from 'react';
import { useSelector } from 'react-redux';
import { Select, Input, Checkbox } from 'antd';

export const TableCol = (handleSave) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const {
    research_grant_type: researchGrantType,
    research_academic_grant_status: researchGrantStatus
  } = staticData;

  return [
    {
      key: 'application_type',
      dataIndex: 'application_type',
      title: 'Grant Type',
      render: (value, row) => {
        const _researchGrantType = researchGrantType.filter(
          (item) => item.value === Number(value)
        );
        const _researchGrant = _researchGrantType[0];

        return (
          <Select
            style={{ width: '250px' }}
            options={[
              ...researchGrantType.map((item) => ({
                value: item.value,
                label: <div>{item.label}</div>
              }))
            ]}
            defaultValue={_researchGrant.value}
            placeholder="Select a Event"
            onChange={(e) => handleSave({ ...row, application_type: e })}
          />
        );
      }
    },
    {
      key: 'detail',
      dataIndex: 'detail',
      title: 'Details',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) => handleSave({ ...row, detail: e.target.value })}
        />
      )
    },
    {
      key: 'is_lead_applicant',
      dataIndex: 'is_lead_applicant',
      title: 'Lead Applicant',
      render: (value, row) => (
        <Checkbox
          placeholder="Enter"
          defaultChecked={value}
          onChange={(e) =>
            handleSave({ ...row, is_lead_applicant: e.target.checked })
          }
        />
      )
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      render: (value, row) => {
        const _researchGrantStatus = researchGrantStatus?.filter(
          (item) => item.value === Number(value)
        );
        const _grantStatus = _researchGrantStatus[0];

        return (
          <Select
            style={{ width: '250px' }}
            options={[
              ...researchGrantStatus.map((item) => ({
                value: item.value,
                label: <div>{item.label}</div>
              }))
            ]}
            defaultValue={_grantStatus.value}
            placeholder="Select a Event"
            onChange={(e) => handleSave({ ...row, status: e })}
          />
        );
      }
    }
  ];
};
