import React from 'react';
import { Table } from 'antd';

import { TableCol } from './getTableCol';

import { planStruct } from './struct';
import { getAsNumber } from '../../../../../../utilities/numberConvertion';

export const Plan = ({ planing, handlePlaning }) => {
  const handleSave = (row) => {
    const currentCourses = [...planing];
    const index = currentCourses.findIndex(
      (item) => row.apa.id === item.apa.id
    );
    const item = currentCourses[index];

    row.semester1 = getAsNumber(row.semester1);
    row.semester2 = getAsNumber(row.semester2);
    row.semester3 = getAsNumber(row.semester3);

    currentCourses.splice(index, 1, {
      ...item,
      ...row
    });
    handlePlaning(currentCourses);
  };

  const columns = [...TableCol(handleSave)];

  return <Table {...planStruct.table} columns={columns} dataSource={planing} />;
};
