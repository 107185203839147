import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Popconfirm, Flex, Button } from 'antd';

import { TableCol } from './getTableCol';

import { planStruct } from './struct';
import { getRandomId } from '../../../../../../utilities/randomId';
import { getAsNumber } from '../../../../../../utilities/numberConvertion';

export const Plan = ({ planing, handlePlaning }) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { co_curricular_roles: coCurricularRoles } = staticData;
  const defaultCoCurricularRoles = coCurricularRoles[0].id;

  const handleAdd = () => {
    const newPlaning = {
      key: getRandomId(),
      role: defaultCoCurricularRoles,
      semester_1: 0,
      semester_2: 0,
      semester_3: 0,
      apa_cycle: false
    };
    handlePlaning([...planing, newPlaning]);
  };

  const handleSave = (row) => {
    const currentCourses = [...planing];
    const index = currentCourses.findIndex((item) => row.key === item.key);
    const item = currentCourses[index];

    row.semester_1 = getAsNumber(row.semester_1);
    row.semester_2 = getAsNumber(row.semester_2);
    row.semester_3 = getAsNumber(row.semester_3);

    currentCourses.splice(index, 1, {
      ...item,
      ...row
    });
    handlePlaning(currentCourses);
  };

  const handleDelete = (key) => {
    const newData = planing.filter((item) => item.key !== key);
    handlePlaning(newData);
  };

  const columns = [
    ...TableCol(handleSave),
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record.key)}
        >
          <a>Delete</a>
        </Popconfirm>
      )
    }
  ];

  return (
    <>
      <Table {...planStruct.table} columns={columns} dataSource={planing} />
      <br />
      <Flex {...planStruct.actionSection}>
        <Button onClick={handleAdd}>Add New Row</Button>{' '}
      </Flex>
    </>
  );
};
