import { Flex, Typography } from 'antd';


const { Text } = Typography;

export const TableCol = [
  {
    title: 'Role/APA Area',
    dataIndex: 'role',
    key: 'role',
    render: role => (
      <Flex vertical>
        {role.map((item, index) => (
          <Text key={index}>{item.name}</Text>
        ))}
      </Flex>
    ),
    editable: false,
    align: 'center' 
  },
  {
    title: 'Lecture',
    dataIndex: 'lecture',
    key: 'lecture',
    render: lecture => (
      <Flex vertical>
        {lecture.map((item, index) => (
          <Text key={index}>{item.value}</Text>
        ))}
      </Flex>
    ),
    editable: false,
    align: 'center' 
  },
  {
    title: 'Assistant Professor and Above',
    dataIndex: 'AssistantProf',
    key: 'AssistantProf',
    render: AssistantProf => (
      <Flex vertical>
        {AssistantProf.map((item, index) => (
          <Text key={index}>{item.value}</Text>
        ))}
      </Flex>
    ),
    editable: false,
    align: 'center' 
  },
];