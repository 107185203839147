import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Popconfirm, Flex, Button } from 'antd';

import { TableCol } from './getTableCol';

import { planStruct } from './struct';
import { getRandomId } from '../../../../../../utilities/randomId';
import { getAsNumber } from '../../../../../../utilities/numberConvertion';

export const Plan = ({ planing, handlePlaning }) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { research_point_data: researchPointData } = staticData;
  const defaultOutCome = researchPointData[0].id;

  const handleAdd = () => {
    const newPlaning = {
      key: getRandomId(),
      outcome: defaultOutCome,
      is_lead_author: false,
      title_or_description: '',
      point_split_percentage: 0,
      has_multiple_iub_authors: false,
      citation: 0
    };
    handlePlaning([...planing, newPlaning]);
  };

  const handleSave = (row) => {
    const currentOutcome = [...planing];
    const index = currentOutcome.findIndex((item) => row.key === item.key);
    const item = currentOutcome[index];

    row.point_split_percentage = getAsNumber(row.point_split_percentage);
    row.citation = getAsNumber(row.citation);

    currentOutcome.splice(index, 1, {
      ...item,
      ...row
    });
    handlePlaning(currentOutcome);
  };

  const handleDelete = (key) => {
    const newData = planing.filter((item) => item.key !== key);
    handlePlaning(newData);
  };

  const columns = [
    ...TableCol(handleSave),
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record.key)}
        >
          <a>Delete</a>
        </Popconfirm>
      )
    }
  ];

  return (
    <>
      <Table {...planStruct.table} columns={columns} dataSource={planing} />
      <br />
      <Flex {...planStruct.actionSection}>
        <Button onClick={handleAdd}>Add New Row</Button>{' '}
      </Flex>
    </>
  );
};
