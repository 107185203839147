import React from 'react';
import { Typography, Flex, Tag } from 'antd';
import * as AntdIcons from '@ant-design/icons';

import { userInfoStruct } from './struct';

const { Text, Title } = Typography;
const {
  user: userStruct,
  userEmail,
  userName,
  userDepartment,
  userDesignation,
  userSchool
} = userInfoStruct;

export const UserInfo = ({ user }) => {
  const { name, designation, department, school, email, role } = user || {};
  
  if (!user) {
    return null
  }

  return (
    user && (
      <Flex {...userStruct}>
        <Title {...userName}>{name ? name.toUpperCase() : ''},{" "}{role}</Title>

        <Tag {...userEmail}>
          <AntdIcons.MailOutlined />
          <Text>{email}</Text>
        </Tag>
        <Tag {...userDesignation}>
          <AntdIcons.CrownOutlined />
          <Text>{designation}</Text>
        </Tag>
        <Tag {...userDepartment}>
          <AntdIcons.PartitionOutlined />
          <Text>{department}</Text>
        </Tag>
        <Text {...userSchool}>{school}</Text>
      </Flex>
    )
  );
};
