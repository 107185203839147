import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { Button, Form, Input, Flex, Image, message, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import auth from '../../../../services/auth.services';
import { login } from '../../../../actions/login.action';

import {
  loginFormStruct,
  emailFieldStruct,
  buttonSectionStruct,
  passwordFieldStruct,
  buttonStruct,
  loginSectionStruct,
  loginBannerStruct,
  homeSectionTitle,
  homeSectionSemiTitle,
  forgetPassStruct
} from './struct';

import { responseStatus } from '../../../../const/reponseStatus';

import BannerImg from './../../images/iub.png';
import apis from '../../../../services/Apis';

const { Title } = Typography;

export const Login = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    auth
      .LoginAuth(values)
      .then((response) => {
        if (responseStatus[response.status] === 'SUCCESS') {
          const { access } = response.data;
          const decoded_token = jwtDecode(access);

          dispatch(login(decoded_token));

          auth.storeToken(access);
          auth.storeUserInfo(JSON.stringify(response.data));
          setIsLoggedIn(true);
        } else return messageApi.error(response.data.message);
      })
      .catch((error) => {
        const { response } = error;
        const { status, data } = response || {};
        const { detail: errorMsg } = data || {};

        if (response && status === 401) {
          messageApi.error(errorMsg);
        } else messageApi.error('Server Error');
      });
  };

  if (isLoggedIn) return <Navigate to="/home" />;

  return (
    <>
      {contextHolder}
      <Flex {...loginSectionStruct}>
        <Image {...loginBannerStruct} src={BannerImg} />
        <Title {...homeSectionTitle} level={3}>
          Independent University, <br />
          <span>Bangladesh</span>
        </Title>
        <Title {...homeSectionSemiTitle}>Annual Performance Appraisal</Title>
        <Form {...loginFormStruct} onFinish={handleSubmit}>
          <Form.Item {...emailFieldStruct}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item {...passwordFieldStruct}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item {...buttonSectionStruct}>
            <Button {...buttonStruct}>Login</Button>
            <Link
              {...forgetPassStruct}
              to={apis.BASE + apis.FORGET_PASS}
              as="a"
              target="_blank"
              rel="noopener noreferrer"
            >
              Forgot password
            </Link>
          </Form.Item>
        </Form>
      </Flex>
    </>
  );
};
