import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

const getOptions = (list) => {
  return list.map((item) => ({
    value: item.value,
    label: <div>{item.label}</div>,
    credit: item.credit
  }));
};

export const TableCol = (action) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { course_type: courseType } = staticData;

  return [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Course',
      render: (value, row) => {
        const _courseType =
          courseType && courseType.length
            ? courseType.filter((item) => item.value === Number(value))
            : [];
        const _course = _courseType.length ? _courseType[0] : {};

        const { value: courseValue } = _course;

        return courseType && courseType.length ? (
          <Select
            style={{ width: '250px' }}
            options={getOptions(courseType)}
            defaultValue={courseValue}
            placeholder="Select a Course"
            onChange={(e, selected) => {
              action({
                ...row,
                name: e,
                credit: selected.credit
              });
            }}
          />
        ) : (
          <></>
        );
      }
    },
    {
      key: 'credit',
      dataIndex: 'credit',
      title: 'Credits'
    }
  ];
};
