export const table = {
  wrapper: {
    style: {
      borderRadius: "8px",
    },
  },

  header: {
    gap: "middle",
    justify: "space-between",
    wrap: "wrap"
  },

  date: {
    picker: "year",
    allowClear: false,
  },

  addSemesterButton: {
    type: "primary",
  },

  saveButton: {
    type: "primary",
    style: {
      background: "#0eaf65",
    },
  },
};
