//static
const initialState = {
  staticData: null,
  staticLoading: false,
  staticError: false,
  staticErrorMsg: "",
};

const staticAction = (state = initialState, action) => {
  switch (action.type) {
    case "STATIC": {
      return {
        ...state,
        staticLoading: action.loading,
        staticData: action.data,
        staticError: action.error,
        staticErrorMsg: action.errorMsg,
      };
    }

    default:
      return state;
  }
};

export default staticAction;
