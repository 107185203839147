import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Select, Typography, Input } from 'antd';

const { Text } = Typography;

export const TableCol = (handleSave) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { research_roles: researchRoles } = staticData;

  return [
    {
      key: 'role',
      dataIndex: 'role',
      title: 'Role',
      render: (value, row) => {
        const _roles = researchRoles.filter((item) => item.id === value);
        const _role = _roles[0];

        return (
          <Select
            style={{ width: '300px', minHeight: '64px' }}
            options={[
              ...researchRoles.map((event) => ({
                value: event.id,
                label: (
                  <Flex vertical>
                    <Text>{event?.name}</Text>
                    <Text type="secondary">({event?.point} APA Points)</Text>
                  </Flex>
                )
              }))
            ]}
            defaultValue={_role.id}
            placeholder="Select a Event"
            onChange={(e) => handleSave({ ...row, role: e })}
          />
        );
      }
    },
    {
      key: 'detail',
      dataIndex: 'detail',
      title: 'Details',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) => handleSave({ ...row, detail: e.target.value })}
        />
      )
    }
  ];
};
