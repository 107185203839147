export const getDefaultData = () => [
  {
    semester: 1,
    number_of_students: 0,
  },
  {
    semester: 2,
    number_of_students: 0,
  },
  {
    semester: 3,
    number_of_students: 0,
  },
];
