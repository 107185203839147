import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Button, Flex } from 'antd';

import { Plans } from './Plans';
import { TableDescription } from '../../components/TableDescription';
import { confirmations } from './../../components/Confirmations';

import { SecureGet } from '../../services/axiosCall';
import apis from '../../services/Apis';

import { IsFormSaved } from '../../context/FromStatusContext';

import { formRules } from '../../const/formRules';
import { actionsStruct } from './struct';

const { actionSection, actionButtonNext, actionButtonPrev } = actionsStruct;

const Planing = ({ menu }) => {
  const navigate = useNavigate();
  const { isFormSaved } = useContext(IsFormSaved);

  const [loading, setLoading] = useState(false);
  const handleLoading = (loading) => setLoading(loading);

  const [apaShortDes, setApaShortDes] = useState(null);
  const {
    form_no: formNo,
    form_name: formName,
    max_point: maxPoints
  } = apaShortDes || {};
  const handleApaShortDes = (des) => setApaShortDes(des);

  const [apaRules, setApaRules] = useState(null);
  const handleApaRules = (rules) => setApaRules(rules);

  const fetchApaShortDes = (id) => {
    handleApaShortDes(null);
    handleLoading(true);

    SecureGet({ url: `${apis.FORM_LIST}/${id}` })
      .then((response) => {
        handleApaShortDes(response.data);
        handleLoading(false);
      })
      .catch(() => {
        handleLoading(false);
      });
  };

  useEffect(() => {
    if (menu.currentApaId) fetchApaShortDes(menu.currentApaId);
  }, [menu]);

  useEffect(() => {
    if (apaShortDes) {
      const _formRules = formRules.find((item) => formNo === item.id);
      const { rules } = _formRules || {};
      handleApaRules(rules);
    }
  }, [apaShortDes]);

  return (
    <div>
      {apaShortDes ? (
        <>
          <TableDescription
            formNo={menu.currentMenuId}
            formName={formName}
            maxPoints={maxPoints}
            rules={apaRules}
          />
          <br />
          <Plans menu={menu} />
          <br />

          <Flex {...actionSection}>
            {menu.prevMenu ? (
              <Button
                {...actionButtonPrev}
                onClick={() =>
                  isFormSaved
                    ? navigate(`/planing/${menu.prevMenu}`)
                    : confirmations.forRouteChange(() =>
                        navigate(`/planing/${menu.prevMenu}`)
                      )
                }
              >
                Prev
              </Button>
            ) : (
              <></>
            )}
            {menu.nextMenu ? (
              <Button
                {...actionButtonNext}
                onClick={() =>
                  isFormSaved
                    ? navigate(`/planing/${menu.nextMenu}`)
                    : confirmations.forRouteChange(() =>
                        navigate(`/planing/${menu.nextMenu}`)
                      )
                }
              >
                Next
              </Button>
            ) : (
              <></>
            )}
          </Flex>
        </>
      ) : (
        <Skeleton loading={loading} active />
      )}
    </div>
  );
};

export default Planing;
