import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Layout, Menu, message, Button, Flex, Skeleton } from 'antd';
import * as AntdIcons from '@ant-design/icons';

import Dashboard from '../Dashboard/index';
import Planing from '../Planing/index';
// import Evaluation from '../Evaluation/index';

import { UserInfo } from '../../components/UserInfo/index';
import { BrandLogo, BrandHeadline } from '../../components/Brand';
import { confirmations } from './../../components/Confirmations';

import {
  layoutStruct,
  headerStruct,
  siderStruct,
  contentStruct,
  signOutButtonStruct
} from './struct.js';

import { getApa } from '../../actions/apa.action.js';
import { getStatic } from '../../actions/static.action.js';
import { selectMenu } from '../../actions/menu.js';
import { login, logout } from '../../actions/login.action.js';

import { IsFormSaved } from '../../context/FromStatusContext';

import auth from '../../services/auth.services.js';

import { menuList } from './const.js';

const { header, actions } = headerStruct;
const { Header, Sider, Content } = Layout;

const CustomLayout = ({ isDarkMode, handleTheme }) => {
  const { isFormSaved } = useContext(IsFormSaved);

  const [userInfo, setUserInfo] = useState(null);

  const { status, formNo } = useParams();
  const location = useLocation();

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const { isLoggedIn } = user;
  const apa = useSelector((state) => state.apa);
  const { apaLoading, apaData, apaError, apaErrorMsg } = apa;
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;

  const menu = useSelector((state) => state.menu);

  const getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label
    };
  };

  const items = [
    getItem('Dashboard', '/home', <AntdIcons.PieChartOutlined />),

    getItem('Planing', 'planing', <AntdIcons.EditOutlined />, [
      ...menuList.map((item) =>
        getItem(
          `${item.form_no}~${item.form_name}`,
          `/planing/${item.id}`,
          <AntdIcons.FileOutlined />
        )
      )
    ]),
    // getItem('Evaluation', 'evaluation', <AntdIcons.EditOutlined />, [
    //   ...menuList.map((item) =>
    //     getItem(
    //       `${item.form_no}~${item.form_name}`,
    //       `/evaluation/${item.id}`,
    //       <AntdIcons.FileOutlined />
    //     )
    //   )
    // ])
  ];

  const handleClickOnMenuItem = (e) =>
    isFormSaved
      ? navigate(`${e.key}`)
      : confirmations.forRouteChange(() => navigate(`${e.key}`));

  const fetchApa = () => dispatch(getApa());
  const fetchStatic = () => dispatch(getStatic());

  useEffect(() => {
    if (apaError && apaErrorMsg) messageApi.error(`${apaErrorMsg}`);
  }, [apaError]);

  useEffect(() => {
    const token = auth.retriveToken();
    const parsedUserInfo = JSON.parse(auth.retriveUserInfo());

    if (token) {
      const decoded_token = jwtDecode(token);
      dispatch(login(decoded_token));
      fetchApa();
      fetchStatic();
    } else window.location.href = '/';

    setUserInfo(parsedUserInfo);
  }, [auth.retriveUserInfo]);

  useEffect(() => {
    const selectedApas = apaData.filter((item) =>
      item.form_no.includes(formNo)
    );
    const selectedApa = selectedApas.length ? selectedApas[0] : {};

    const selectedMenus = menuList.filter((item) =>
      item.form_no.includes(formNo)
    );
    const selectedMenu = selectedMenus.length ? selectedMenus[0] : {};

    dispatch(
      selectMenu({
        selectedApas: selectedApas,
        apaId: selectedApa.id,
        apaStatus: status,
        menuType: status,
        menuId: formNo,
        nextMenu: selectedMenu.next,
        prevMenu: selectedMenu.prev
      })
    );
  }, [formNo, location, JSON.stringify(apaData)]);

  let torender = null;
  if (menu.currentMenuType === 'planing') torender = <Planing menu={menu} />;
  // else if (menu.currentMenuType === 'evaluation')
  //   torender = <Evaluation menu={menu} />;
  else torender = <Dashboard menu={menu} />;

  return user && isLoggedIn ? (
    <Layout {...layoutStruct}>
      <Sider {...siderStruct}>
        <BrandLogo />
        <UserInfo user={userInfo} />

        {apaLoading ? (
          <Skeleton loading={apaLoading} active />
        ) : (
          <Menu
            selectedKeys={[`/${menu.currentMenuType}/${menu.currentMenuId}`]}
            mode="inline"
            items={items}
            onClick={handleClickOnMenuItem}
          />
        )}
      </Sider>
      <Layout>
        <Header>
          <Flex {...header}>
            <BrandHeadline />
            <Flex {...actions}>
              <Button onClick={handleTheme}>
                Change Theme to {isDarkMode ? 'Light' : 'Dark'}
              </Button>
              <Button
                {...signOutButtonStruct}
                icon={<AntdIcons.PoweroffOutlined />}
                onClick={() => dispatch(logout())}
              >
                Sign Out
              </Button>
            </Flex>
          </Flex>
        </Header>
        {apaData && apaData.length && staticData ? (
          <Content {...contentStruct}>
            {contextHolder}
            {torender}
          </Content>
        ) : (
          <></>
        )}
      </Layout>
    </Layout>
  ) : (
    <></>
  );
};

export default CustomLayout;
