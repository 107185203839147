export const distributionTableDetails = '*For Lecturers, 10% of the Research, under special circumstances, may be shifted to the Service component, OR 10% of the service can be shifted to research which is subject to prior approval of the HoD and Dean, and incorporated accordingly in the Annual Performance Agreement of the faculty submitted to the respective supervisors at the beginning of the APA cycle.<br>** No faculty member/ HoD/ Dean can have zero teaching load in any given semester'
export const distributionTableData = [
  {
    key: '1',
    role: [
      { name: 'Teaching' },
      { name: 'Research' },
      { name: 'Service' },
    ],
    lecture: [
      { value: '50%' },
      { value: '20%' },
      { value: '30%' },
    ],
    AssistantProf: [
      { value: '40%' },
      { value: '40%' },
      { value: '20%' },
    ]
  }
];