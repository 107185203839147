import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Button, Flex, Popconfirm } from 'antd';

import { TableCol } from './getTableCol';

import { courseStruct } from './struct';
import { getRandomId } from '../../../../../../utilities/randomId';

const { table, actionSection } = courseStruct;

export const Course = ({ courses, semesterName, updateSemester }) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;

  const { course_type: courseType } = staticData;
  const defaultCourse = courseType && courseType.length ? courseType[0] : {};
  const { value, credit } = defaultCourse;

  const [_courses, setCourses] = useState(
    courses && courses.length ? courses : []
  );
  const handleCourses = (courses) => setCourses(courses);

  const handleAdd = () => {
    const newPlaning = {
      key: getRandomId(),
      name: value,
      credit
    };
    handleCourses([..._courses, newPlaning]);
  };

  const handleSave = (row) => {
    const currentCourses = [..._courses];
    const index = currentCourses.findIndex((item) => row.key === item.key);
    const item = currentCourses[index];

    row.credit = Number(row.credit);

    currentCourses.splice(index, 1, {
      ...item,
      ...row
    });
    handleCourses(currentCourses);
  };

  const handleDelete = (key) => {
    const newData = _courses.filter((item) => item.key !== key);
    handleCourses(newData);
  };

  const columns = [
    ...TableCol(handleSave),
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => {
        const { key } = record;

        return (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(key)}
          >
            <a>Delete</a>
          </Popconfirm>
        );
      }
    }
  ];

  useEffect(() => {
    updateSemester(semesterName, _courses);
  }, [_courses, semesterName]);

  return courses ? (
    <>
      <Table {...table} columns={columns} dataSource={_courses} />
      <br />

      <Flex {...actionSection}>
        <Button onClick={handleAdd}>Add New Course</Button>{' '}
      </Flex>
    </>
  ) : (
    <></>
  );
};
