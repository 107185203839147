import React from 'react'
import { Table } from 'antd';
import { distributionTableData } from '../../../const/dashboard/workDistribution';
import { TableCol } from './getTableCol';
import { staticStruct } from './struct';

const StaticTable = () => {

  const columns = [
    ...TableCol.map((col) => {
      return {
        ...col,
        align:'center'
      };
    })
  ];

  
  return (
    <Table
      {...staticStruct}
      columns={columns}
      dataSource={distributionTableData}
      pagination={false}
    />
  )
}

export default StaticTable