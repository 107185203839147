//apa
const initialState = {
    apaData: [],
    apaLoading: false,
    apaError: false,
    apaErrorMsg: "",
};

const apaAction = (state = initialState, action) => {
    switch (action.type) {
        case "APA": {
            return {
                ...state,
                apaLoading: action.loading,
                apaData: action.data,
                apaError: action.error,
                apaErrorMsg: action.errorMsg,
            };
        }

        default:
            return state;
    }
};

export default apaAction;
