import React from 'react';
import { Typography, Tag, Flex, List } from 'antd';

import { header } from './struct';

const { Title } = Typography;
const { section, headline, tagSection, tag } = header;

const createMarkup = (markUp) => {
  return { __html: markUp };
};

export const TableDescription = ({ formNo, formName, maxPoints, rules }) => {
  return (
    <List
      header={
        <Flex {...section}>
          <Title {...headline}>
            {formNo} ~ {formName}
          </Title>

          <Flex {...tagSection}>
            Max APA Point:
            <Tag {...tag}>{maxPoints || '...'}</Tag>
          </Flex>
        </Flex>
      }
      dataSource={rules || []}
      renderItem={(item) => (
        <List.Item>
          <div key={item} dangerouslySetInnerHTML={createMarkup(item)} />
        </List.Item>
      )}
    />
  );
};
