import axios from "axios";
import auth from "./auth.services";
import apis from "./Apis";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response =>  response,
  error => Promise.reject(error)
);

const addTokenToHeaders = () => {
  const token = auth.retriveToken();
  if (token) {return `Bearer ${token}`}
};

const handleTokenRefresh = async (error) => {
  if (error.response && error.response.status === 401 && !error.config._isRetry) {
    error.config._isRetry = true; // Prevent infinite loop
    
    const response = await auth.RefreshToken();
    const { data: { access, refresh } } = response;
    
    const userInfoString = auth.retriveUserInfo();
    const userInfo = JSON.parse(userInfoString);
    
    auth.storeToken(access);
    auth.storeUserInfo(JSON.stringify({ ...userInfo, refresh }));
    
    error.config.headers.Authorization = `Bearer ${access}`;
    
    return await axiosInstance(error.config);
  }
  
  return Promise.reject(error);
};

export const Get = (p) => {
  return axiosInstance({
    method: "get",
    baseURL: apis.BASE,
    ...p,
  });
};

export const Post = (p) => {
  return axiosInstance({
    baseURL: apis.BASE,
    method: "post",
    ...p,
  });
};

export const Put = (p) => {
  return axiosInstance({
    baseURL: apis.BASE,
    method: "put",
    ...p,
  });
};

export const SecureGet = async (p) => {
  try {
    return await axiosInstance({
      method: "get",
      baseURL: apis.BASE,
      ...p,
      headers: {
        Authorization: addTokenToHeaders(),
      },
      params: { ...p.params },
    });
  } catch (error) {
    return await handleTokenRefresh(error);
  }
};

export const SecurePost = async (p) => {
  try {
    return await axiosInstance({
      method: "post",
      baseURL: apis.BASE,
      ...p,
      headers: {
        Authorization: addTokenToHeaders(),
      },
      params: { ...p.params },
    });
  } catch (error) {
    return await handleTokenRefresh(error);
  }
};

export const SecurePut = async (p) => {
  try {
    return await axiosInstance({
      method: "put",
      baseURL: apis.BASE,
      ...p,
      headers: {
        Authorization: addTokenToHeaders(),
      },
      params: { ...p.params },
    });
  } catch (error) {
    return handleTokenRefresh(error);
  }
};


export const SecureUpload = async (p) => {
  try {
    return await axiosInstance({
      method: "post",
      baseURL: apis.BASE,
      ...p,
      headers: {
        Authorization: addTokenToHeaders(),
        'content-type': 'multipart/form-data' 
      },
      params: { ...p.params },
    });
  } catch (error) {
    return await handleTokenRefresh(error);
  }
};

export const SecureDelete = async (p) => {
  try {
    return await axiosInstance({
      method: "delete",
      baseURL: apis.BASE,
      ...p,
      headers: {
        Authorization: addTokenToHeaders(),
      },
      params: { ...p.params },
    });
  } catch (error) {
    return await handleTokenRefresh(error);
  }
};