import { useCallback, useEffect, useState, useContext } from "react";

import { IsFormSaved } from '../context/FromStatusContext';

import { SecureGet, SecurePost, SecurePut } from "../services/axiosCall";

const usePlanning = ({
  url,
  formId,
  userDetails,
  selectedYear,
  messageApi,
  convertToTableData,
  convertToRequestData,
}) => {
  const { setIsFormSaved } = useContext(IsFormSaved);

  // this value will decide is it all ready create or not
  const [id, setId] = useState(null);
  const handleId = (id) => setId(id);

  const [fetchLoading, setFetchLoading] = useState(false);
  const handleFetchLoading = (loading) => setFetchLoading(loading);

  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateLoading = (loading) => setUpdateLoading(loading);

  const [planing, setPlaning] = useState([]);
  const handlePlaning = (plan) => setPlaning(plan);

  const [_planing, _setPlaning] = useState([]);
  const _handlePlaning = (plan) => _setPlaning(plan);

  const [updated, setUpdated] = useState(false);

  console.log(planing, "planing")

  const fetchPlaning = useCallback((year, formId) => {
    handlePlaning([]);
    _handlePlaning([]);
    handleFetchLoading(true);

    SecureGet({
      url: `${url}`,
      params: {
        year,
        apa_form: formId,
      },
    })
      .then((response) => {
        handlePlaning(
          response.data.results &&
            response.data.results.length &&
            response.data.results[0].planning
            ? convertToTableData
              ? convertToTableData(response.data.results[0].planning)
              : response.data.results[0].planning
            : []
        );
        handleFetchLoading(false);

        handleId(
          response.data.results &&
            response.data.results.length &&
            response.data.results[0].id
            ? response.data.results[0].id
            : null
        );

        _handlePlaning(
          response.data.results &&
            response.data.results.length &&
            response.data.results[0].planning
            ? convertToTableData
              ? convertToTableData(response.data.results[0].planning)
              : response.data.results[0].planning
            : []
        );
      })
      .catch(() => {
        handleFetchLoading(false);
      });
  }, []);

  const updatePlaning = () => {
    handleUpdateLoading(true);

    const newPlan = {
      apa_form: formId,
      year: selectedYear,
      planning: convertToRequestData ? convertToRequestData(planing) : planing,
      user: userDetails,
    };
    if (id) {
      SecurePut({
        url: `${url}/${id}`,
        data: { ...newPlan },
      })
        .then(() => {
          fetchPlaning(selectedYear, formId);

          handleUpdateLoading(false);
          messageApi.success("Successfully updated");
        })
        .catch(() => {
          handleUpdateLoading(false);
          messageApi.success("Something went wrong");
        });
    } else {
      SecurePost({
        url: `${url}`,
        data: { ...newPlan },
      })
        .then(() => {
          fetchPlaning(selectedYear, formId);

          handleUpdateLoading(false);
          messageApi.success("Successfully updated");
        })
        .catch(() => {
          handleUpdateLoading(false);
          messageApi.success("Something went wrong");
        });
    }
  };

  useEffect(() => {
    if (formId && selectedYear) {
      fetchPlaning(selectedYear, formId)
    };
  }, [fetchPlaning, formId, selectedYear, url, userDetails]);

  useEffect(() => {
    console.log(planing, _planing, "ARAFAT")
    if (JSON.stringify(planing) === JSON.stringify(_planing)) {
      setIsFormSaved(true);
      setUpdated(false)
    }else {
      setIsFormSaved(false); 
      setUpdated(true)
    };
  }, [_planing, planing]);

  useEffect(()=>{
    setIsFormSaved(true)
  },[]);

  return {
    planing,
    updated,
    fetchLoading,
    updateLoading,
    updatePlaning,
    handlePlaning,
  };
};

export default usePlanning;
