export const date = {
    picker: "year",
    allowClear: false,
}

export const vrFlex = {
    vertical: true,
    gap: "middle",
    justify: "space-between",
    wrap: "wrap"
}

export const hrFlex = {
    gap: "middle",
    justify: "space-between",
    wrap: "wrap"
}