export const planStruct = {
  wrapper: {
    vertical: true,
    gap: "middle",
    style: {
      background: "rgb(245, 245, 245)",
      borderRadius: "8px",
    },
  },
  table: {
    bordered: true,
    pagination: false,
  },

  actionSection: {
    justify: "flex-end",
  },
};
