export const logoStruct = {
    logoSection:{ 
        style:{
            borderBottom: '1px solid #5a5ab5'
        }
    },

    logo:{
        width: "90px",
        height: "90px"
    }
};

export const headlineStruct = {
    headlineSection: {
        vertical: true,
        gap: "8px"
    },

    headline:{
        level:4
    }
}