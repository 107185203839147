export const table = {
  wrapper: {
    style: {
      borderRadius: "8px",
    },
  },

  header: {
    gap: "middle",
    justify: "space-between",
    wrap: "wrap"
  },

  addSemesterButton: {
    type: "primary",
  },

  saveButton: {
    type: "primary",
    style: {
      background: "#0eaf65",
    },
  },

  actionsSection:{
    gap: "middle",
    wrap: "wrap",
    align: "center"
  },


  resetButton :{
    type:'primary',
    style:{
      background:'#ff8399'
    }
  }
};
