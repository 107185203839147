export const getDefaultData = () => [
  {
    apa: {
      id: "course_module_design",
      area: "Course /Module Design",
      scoringMethod: "20 APA Points/ course",
    },
    semester1: 0,
    semester2: 0,
    semester3: 0,
  },
  {
    apa: {
      id: "taking_per_audit",
      area: "Taking Peer Audit",
      scoringMethod: "5 APA points/ Audit. (Max. 1 per semester)",
    },
    semester1: 0,
    semester2: 0,
    semester3: 0,
  },
  {
    apa: {
      id: "course_file_completion",
      area: "Course File Completion",
      scoringMethod: "5 APA per File",
    },
    semester1: 0,
    semester2: 0,
    semester3: 0,
  },
  {
    apa: {
      id: "new_course_teaching",
      area: "New Course Teaching",
      scoringMethod: "10 APA per course",
    },
    semester1: 0,
    semester2: 0,
    semester3: 0,
  },
];
