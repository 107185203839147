import React from 'react';
import { useSelector } from 'react-redux';
import { Select, Typography, Flex, Input, Checkbox } from 'antd';

import { conditionalFields } from './const';

const { Text } = Typography;
const conditionalOutcome = conditionalFields['CITATIONS'];

export const TableCol = (handleSave) => {
  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { research_point_data: researchPointData, outcome_type: outComeType } =
    staticData;

  return [
    {
      key: 'outcome',
      dataIndex: 'outcome',
      title: 'Outcome',
      render: (value, row) => {
        const _researchPointData = researchPointData.filter(
          (item) => item.id === value
        );
        const _researchPoint = _researchPointData[0];

        return (
          <Select
            style={{ width: '300px', minHeight: '88px' }}
            options={[
              ...researchPointData.map((item) => ({
                value: item.id,
                outcomeId: item.outcome_type,
                label: (
                  <Flex vertical>
                    <Text>
                      {
                        outComeType.find(
                          (outcome) => outcome.value === item.outcome_type
                        ).label
                      }
                    </Text>
                    <Text>{item.publishing_venue}</Text>
                    <Text type="secondary">({item.point} APA Points)</Text>
                  </Flex>
                )
              }))
            ]}
            defaultValue={_researchPoint.id}
            placeholder="Select a Event"
            onChange={(e) => {
              const updatedRow =
                e === conditionalOutcome
                  ? {
                      ...row,
                      is_lead_author: '',
                      title_or_description: '',
                      point_split_percentage: '',
                      has_multiple_iub_authors: '',
                      citation: '',
                      outcome: e
                    }
                  : { ...row, outcome: e };
              handleSave(updatedRow);
            }}
          />
        );
      }
    },
    {
      key: 'is_lead_author',
      dataIndex: 'is_lead_author',
      title: 'Lead Author',
      render: (value, row) =>
        row.outcome === conditionalOutcome ? (
          ''
        ) : (
          <Checkbox
            placeholder="Enter"
            defaultChecked={value}
            onChange={(e) =>
              handleSave({ ...row, is_lead_author: e.target.checked })
            }
          />
        )
    },
    {
      key: 'title_or_description',
      dataIndex: 'title_or_description',
      title: 'Working Title / Brief Description',
      render: (value, row) =>
        row.outcome === conditionalOutcome ? (
          ''
        ) : (
          <Input
            placeholder="Enter"
            value={value}
            onChange={(e) =>
              handleSave({ ...row, title_or_description: e.target.value })
            }
          />
        )
    },
    {
      key: 'point_split_percentage',
      dataIndex: 'point_split_percentage',
      title: 'Point Percentage',
      render: (value, row) =>
        row.outcome === conditionalOutcome ? (
          ''
        ) : (
          <Input
            placeholder="Enter"
            value={value}
            onChange={(e) =>
              handleSave({ ...row, point_split_percentage: e.target.value })
            }
          />
        )
    },
    {
      key: 'has_multiple_iub_authors',
      dataIndex: 'has_multiple_iub_authors',
      title: 'Multiple IUB Authors',
      render: (value, row) =>
        row.outcome === conditionalOutcome ? (
          ''
        ) : (
          <Checkbox
            placeholder="Enter"
            defaultChecked={value}
            onChange={(e) =>
              handleSave({ ...row, has_multiple_iub_authors: e.target.checked })
            }
          />
        )
    },
    {
      key: 'citation',
      dataIndex: 'citation',
      title: 'No of Citation',
      render: (value, row) =>
        row.outcome !== conditionalOutcome ? (
          ''
        ) : (
          <Input
            placeholder="Enter"
            value={value}
            onChange={(e) => handleSave({ ...row, citation: e.target.value })}
          />
        )
    }
  ];
};
