export const userInfoStruct = {
    user: {
        vertical: true,

        style: {
            padding: "24px",
            gap: "8px",
            border: 0,
            borderRadius: 0,
            borderBottom: ".9px solid #dadada",
        },
    },

    userName: {
        level:5
    },

    userEmail: {
        color: "success"
    },

    userDepartment:{
        color: "volcano"
    },

    userDesignation:{
        color: "gold"
    },

    userSchool:{
        type:"secondary"
    }
};
