const initialState = {
  isLoggedIn: false,
  userDetails: {},
  activeRoute: "0",
  userOptions: [],
};

const authAction = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        userDetails: {
          ...action.payload,
        },
      };

    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        userDetails: {},
      };
    default:
      return state;
  }
};

export default authAction;
