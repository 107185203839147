import React from 'react';
import { Card, Flex, Typography } from 'antd';
import { table } from './struct';
import { preamble } from '../../const/dashboard/preamble';
import StaticTable from './tables';
import { distributionTableDetails } from '../../const/dashboard/workDistribution';

const { Title } = Typography;

const Dashboard = () => {

  const createMarkup = (markUp) => {
    return { __html: markUp };
  };

  return (
    <Card {...table.wrapper}>
      <Title {...table.headline}>
        {"A) Preamble"}
      </Title>
      <br />
      <div dangerouslySetInnerHTML={createMarkup(preamble)} />
      <br />
      <br />
      <Title {...table.headline}>
        {"B) Workload Distribution"}
      </Title>
      <StaticTable />
      <br />
      <div dangerouslySetInnerHTML={createMarkup(distributionTableDetails)} />
      <br />
      <br />
      <Title {...table.headline}>
        {"C) APA Point Calculation Method"}
      </Title>
      <br />
      <div >
        Based on the workload weightage described above faculty performance will be assessed on the scale of 5.0
      </div>
      <Flex {...table.img}>
        <img
          alt='APA calculation method'
          src={require('../../assets/apa.png')}
        />
      </Flex>
    </Card>
  )
};

export default Dashboard;
