import React from 'react';
import { useSelector } from 'react-redux';
import { Select, Flex, Typography, Button, message, Card } from 'antd';

import { Course } from '../Course';

import { semesterStruct } from './struct';

const { Title } = Typography;

export const Semester = ({
  semesterDetail,
  removeSemester,
  setSemesterName,
  updateSemester
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const _static = useSelector((state) => state.static);
  const { staticData } = _static;
  const { semester: semesters } = staticData;

  const { courses, semester, tempSemester } = semesterDetail;

  return (
    <Card {...semesterStruct.wrapper}>
      <Flex {...semesterStruct.header}>
        {contextHolder}

        <Flex {...semesterStruct.headline}>
          <Title {...semesterStruct.title}>
            Semester~
            {semester === 1
              ? 'Spring'
              : semester === 2
                ? 'Summer'
                : semester === 3
                  ? 'Autumn'
                  : ''}
          </Title>
          {tempSemester ? (
            <Select
              {...semesterStruct.selectSemesters}
              onChange={(value) => {
                value
                  ? setSemesterName(Number(value), tempSemester)
                  : messageApi.error("Empty can't be a semester name");
              }}
              options={semesters}
            />
          ) : (
            <></>
          )}
        </Flex>
        {!tempSemester ? (
          <Button
            {...semesterStruct.removeButton}
            onClick={() => removeSemester(semester)}
          >
            Remove
          </Button>
        ) : (
          <></>
        )}
      </Flex>
      {courses ? (
        <Course
          courses={courses}
          semesterName={semester}
          updateSemester={updateSemester}
        />
      ) : (
        <></>
      )}
    </Card>
  );
};
