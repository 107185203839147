import { Input } from 'antd';

export const TableCol = (handleSave) => {
  return [
    {
      key: 'detail',
      dataIndex: 'detail',
      title: 'Details',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) => handleSave({ ...row, detail: e.target.value })}
        />
      )
    },
    {
      key: 'number_of_ra',
      dataIndex: 'number_of_ra',
      title: 'Number of RA',
      render: (value, row) => (
        <Input
          placeholder="Enter"
          value={value}
          onChange={(e) => handleSave({ ...row, number_of_ra: e.target.value })}
        />
      )
    }
  ];
};
