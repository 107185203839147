export const planStruct = {
  wrapper: {
    vertical: true,
    gap: "middle",
    style: {
      borderRadius: "8px",
    },
  },
  table: {
    bordered: true,
    pagination: false,
    scroll: {
      x: 500,
    },
  },

  actionSection: {
    justify: "flex-end",
  },
};
