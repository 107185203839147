export const actionsStruct = {
    actionSection: {
      gap: "8px",
      justify: "flex-end",
      wrap: "wrap"
    },
  
    actionButtonNext: {
      type: "primary"
    },

    actionButtonPrev: {
        type: "primary",
        ghost: true
    },
  };
  