import React from 'react';
import { Table } from 'antd';

import { TableCol } from './getTableCol';

import { planStruct } from './struct';
import { getAsNumber } from '../../../../../../utilities/numberConvertion';

export const Plan = ({ planing, handlePlaning }) => {
  const handleSave = (row) => {
    const currentCourses = [...planing];
    const index = currentCourses.findIndex(
      (item) => row.semester === item.semester
    );
    const item = currentCourses[index];

    row.number_of_students = getAsNumber(row.number_of_students);

    currentCourses.splice(index, 1, {
      ...item,
      ...row
    });
    handlePlaning(currentCourses);
  };

  const columns = [...TableCol(handleSave)];

  return <Table {...planStruct.table} columns={columns} dataSource={planing} />;
};
