export const semesterStruct = {
  wrapper: {
    style: {
      borderRadius: "8px",
    },
  },

  header: {
    gap: "middle",
    align: "center",
    justify: "space-between",
  },

  headline: {
    align: "center",
    gap: "middle",
    justify: "space-between",
    wrap: "wrap",
  },

  title: {
    level: 5,
  },

  removeButton: {
    type: "primary",
    danger: true,
  },

  selectSemesters: {
    style: { width: "200px" },
    placeholder: "Select a semester",
  },
};
